import { sheetOptions } from "@constants/sheets/sheetOptions";
import {
  BarChartIcon,
  GearIcon,
  ClipboardIcon,
  RocketIcon,
  CodeIcon,
  Pencil1Icon,
  ClipboardCopyIcon,
  LayersIcon,
  DashboardIcon,
  ComponentPlaceholderIcon,
  ClockIcon,
  CounterClockwiseClockIcon,
  ReaderIcon,
  EnvelopeClosedIcon,
} from "@radix-ui/react-icons";
import React from "react";
import { valuationPlatform } from "@constants/staticPages/platform";
import { forecastPlatform } from "@constants/staticPages/platform";

const ICON_SIZE_SIDEBAR = 17;

export const publicNavigation = [
  {
    label: "Platform",
    subitems: [
      {
        label: forecastPlatform.title,
        tagline: forecastPlatform.tagline,
        href: forecastPlatform.url,
        icon: forecastPlatform.icon,
      },
      {
        label: valuationPlatform.title,
        tagline: valuationPlatform.tagline,
        href: valuationPlatform.url,
        icon: valuationPlatform.icon,
      },
    ],
  },

  {
    label: "Resources",
    subitems: [
      {
        label: "Resource center",
        tagline: "Learn about forecasting and valuation",
        href: "/resources",
        icon: ReaderIcon, // Replace with actual icon reference if needed
      },
      {
        label: "Contact us",
        tagline: "Get in touch with our team",
        href: "/contact",
        icon: EnvelopeClosedIcon, // Replace with actual icon reference if needed
      },
    ],
  },

  {
    label: "Pricing",
    href: "/pricing",
  },
];

export const publicNavigationFooter = [
  {
    label: "Company",
    subitems: [
      {
        label: "About us",
        href: "/about-us",
      },

      {
        label: "Contact us",
        href: "/contact",
      },
    ],
  },
];

const inputSheets = Object.keys(sheetOptions)
  .sort(
    (a, b) =>
      sheetOptions[a].sideBarLinkOrder - sheetOptions[b].sideBarLinkOrder
  )
  .map((key) => ({
    key,
    label: sheetOptions[key].title,
  }));

export const getSidebarLinks = ({ forecastId, companyId }: any) => {
  if (!forecastId || !companyId) return null;

  const sidebarLinks = [
    {
      label: "Dashboard",
      dontShowLink: true,

      sections: mapSections(
        forecastId,
        [
          {
            key: "dashboard",
            label: "Dashboard",
            icon: createIcon({
              iconComponent: DashboardIcon,
              size: ICON_SIZE_SIDEBAR,
            }),
          },
        ],
        "outputs"
      ),
    },

    {
      label: "Actuals",
      dontShowLink: true,

      sections: mapSections(
        forecastId,
        [
          {
            key: "actuals",
            label: "Actuals",
            icon: createIcon({
              iconComponent: CounterClockwiseClockIcon,
              size: ICON_SIZE_SIDEBAR,
            }),
          },
        ],
        "inputs"
      ),
    },

    // { key: "actuals", label: "Actuals" },
    {
      label: "Inputs",
      icon: createIcon({
        iconComponent: ClipboardIcon,
        size: ICON_SIZE_SIDEBAR,
      }),
      sections: mapSections(
        forecastId,
        [
          ...inputSheets,
          {
            key: "valuation",
            label: "Valuation",
            icon: createIcon({
              iconComponent: CodeIcon,
              size: ICON_SIZE_SIDEBAR,
            }),
          },
        ],
        "inputs"
      ),
    },

    {
      label: "Outputs",
      icon: createIcon({
        iconComponent: BarChartIcon,
        size: ICON_SIZE_SIDEBAR,
      }),

      sections: mapSections(
        forecastId,
        [
          { key: "finstat", label: "Financial statements" },
          { key: "valuation", label: "Valuation" },
          { key: "ratios", label: "Ratios" },
        ],
        "outputs"
      ),
    },

    {
      label: "Analyse",
      dontShowLink: true,

      icon: createIcon({
        iconComponent: GearIcon,
        size: ICON_SIZE_SIDEBAR,
      }),

      sections: [
        {
          label: "Analysis",
          href: `/forecast/${forecastId}/analyse`,
          icon: createIcon({
            iconComponent: CodeIcon,
            size: ICON_SIZE_SIDEBAR,
          }),
        },
      ],
    },

    {
      label: "Settings",
      icon: createIcon({
        iconComponent: GearIcon,
        size: ICON_SIZE_SIDEBAR,
      }),

      sections: [
        {
          label: "Forecast",
          href: `/forecast/${forecastId}/settings`,
          icon: createIcon({
            iconComponent: GearIcon,
            size: ICON_SIZE_SIDEBAR,
          }),
        },
        {
          label: "Company",
          href: `/company/${companyId}/settings`,
          icon: createIcon({
            iconComponent: GearIcon,
            size: ICON_SIZE_SIDEBAR,
          }),
        },
      ],
    },
  ];

  return sidebarLinks;
};

function createIcon({ iconComponent, size }: any) {
  return React.createElement(iconComponent, {
    width: size,
    height: size,
  });
}

const createHref = (forecastId: string, path: string) =>
  `/forecast/${forecastId}/${path}`;

const mapSections = (forecastId: string, sections: any, basePath: string) =>
  sections.map(({ key, label, icon }: { key: any; label: any; icon: any }) => ({
    label,
    href: createHref(forecastId, `${basePath}/${key}`),
    detail: {
      item: label,
      sheetEndpoint: `${basePath}/${key}`,
      sectionId: key,
    },
    icon,
  }));
